<template>
  <div>
    <div class="row">
      <div class="col-md-5 mb-3">
        <div class="row">
          <div
            class="col-md-9 dx-item-content dx-toolbar-item-content"
            style="min-width: 350px"
          >
            <div role="group" class="dx-buttongroup dx-widget" tabindex="0">
              <div class="dx-buttongroup-wrapper dx-widget dx-collection">
                <div
                  class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-icon dx-item dx-buttongroup-item dx-item-content dx-buttongroup-item-content dx-buttongroup-first-item dx-shape-standard dx-scheduler-navigator-previous"
                  role="button"
                  aria-label="chevronprev"
                  @click="prevdate"
                >
                  <div class="dx-button-content">
                    <i class="dx-icon dx-icon-chevronprev"></i>
                  </div>
                </div>
                <div
                  class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-text dx-item dx-buttongroup-item dx-item-content dx-buttongroup-item-content dx-shape-standard dx-scheduler-navigator-caption"
                  role="button"
                  aria-label=""
                >
                  <DxDateBox
                    v-model="currentdate"
                    display-format="EEEE dd MMMM yyyy"
                    type="date"
                    picker-type="calendar"
                    apply-value-mode="instantly"
                    class="datebox"
                    :calendarOptions="{ firstDayOfWeek: 1 }"
                  />
                </div>
                <div
                  class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-icon dx-item dx-buttongroup-item dx-item-content dx-buttongroup-item-content dx-shape-standard dx-scheduler-navigator-next"
                  role="button"
                  aria-label="chevronnext"
                  @click="nextdate"
                >
                  <div class="dx-button-content">
                    <i class="dx-icon dx-icon-chevronnext"></i>
                  </div>
                </div>
                <div
                  class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-icon dx-item dx-buttongroup-item dx-item-content dx-buttongroup-item-content dx-buttongroup-last-item dx-shape-standard today-button"
                  role="button"
                  aria-label="today"
                  @click="today"
                >
                  <div class="dx-button-content">
                    <i class="far fa-calendar-check"></i> Today
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="btn-group" :class="{ 'ml-1': isBooking }">
              <button v-if="!showinghistorical" class="btn btn-outline-secondary btn-outline-success" title="Calculate travel times"
                @click="isBookingManagement ? calculatetraveltime() : calculatetBookingTavelTime()">
                <i class="fas fa-cog fa-spin" v-if="calculatingtraveltime"></i>
                <i v-else class="fas fa-route"></i>
              </button>
              <button v-if="!showinghistorical" class="btn btn-outline-secondary" title="Clear travel times" @click="clearalltraveltime">
                <i class="far fa-times-circle"></i>
              </button>
              <!--button
                class="btn btn-outline-secondary btn-outline-success pl-1"
                title="Show travel times"
                @click="showtraveltimeFn"
              >
                <i class="fas fa-route"></i>
                {{ showtraveltime ? "Hide" : "Show" }}
              </-button-->
            </div>
            <button
              v-if="isBooking"
              class="btn btn-outline-secondary"
              :class="{ 'text-success': showbookingswithproblems }"
              title="Show bookings with source report not selected or invoice not created"
              @click="toggleShowbookingswithproblems()"
            >
              <i class="fas fa-exclamation-triangle"></i>
            </button>
            
          </div>
        </div>
      </div>
      <div class="col-md-2 pr-0">
        <multiselect-text
          class="inspectorfilter"
          v-model="selectedinspectors"
          :options="inspectoroptions"
          :allow-empty="true"
          :taggable="false"
          :searchable="false"
          @select="onInspectorSelect"
          @remove="onInspectorRemove"
          placeholder="Show/hide Inspectors"
          trackBy="id"
          label="name"
          multiple
        >
          <template #selection="props">
            <div v-if="props.values.length">
              Showing {{ props.values.length }} Inspectors
            </div>
          </template>
          <template #option="props">
            <div class="row">
              <div class="col-md-2 pr-0">
                <i
                  :class="{
                    'fas fa-check text-success': isInspectorSelected(
                      props.option.id
                    ),
                  }"
                ></i>
              </div>
              <div class="col-md-10 pl-0">{{ props.option.name }}</div>
            </div>
          </template>
        </multiselect-text>
      </div>
      <div class="col-md-1 pl-0 btn-group">
        <button
          class="btn btn-outline-secondary selectall-button"
          title="All"
          @click="selectallInspectors"
        >
          All
        </button>
        <button
          class="btn btn-outline-secondary selectall-button"
          title="Just me!"
          @click="clearSelectedinspectors"
          :disabled="!loggedininspector.id"
        >
          Me
        </button>
      </div>
      <div class="col-md-2">
        <search-by-string
          placeholder="Search"
          @searchsubmit="search"
          :showserachresulttable="showserachresulttable"
        />
      </div>
      <div class="col-md-1 pl-0 pr-0">
        <button class="btn btn-primary" title="New" @click="newBooking">
          New Booking
        </button>
      </div>
      <div class="col-md-1 pl-0 pr-0">
        <button class="btn btn-primary" title="New" @click="addPersonalJob">
          New Note
        </button>
      </div>
    </div>
    <div class="row" v-if="showserachresulttable">
      <div class="col-auto">
        <i
          class="fas fa-arrow-left fa-2x"
          @click="showserachresulttable = false"
        ></i>
      </div>
      <div class="col-md-11">
        <DxDataGrid
          ref="searchresultgrid"
          height="220px"
          :data-source="results"
          key-expr="id"
          :allow-column-reordering="false"
          :allow-column-resizing="false"
          :column-auto-width="true"
          :show-borders="false"
          :show-column-lines="false"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :auto-navigate-to-focused-row="true"
          :focused-row-key="focusedRowKey"
          no-data-text="No bookings found for given search"
          @row-click="onAppointmentClick"
          @row-dbl-click="onAppointmentDblClick"
          @row-prepared="onSearchTableRowPrepared"
          @cell-prepared="onSearchTableCellPrepared"
        >
          <DxColumn
            width="10%"
            :fixed="true"
            data-field="bookingdatewithday"
            caption="Date"
          />
          <DxColumn
            width="10%"
            :fixed="true"
            cell-template="timeCellTemplate"
            caption="Time"
          />
          <template #timeCellTemplate="{ data }">
            <span style="white-space: pre-wrap">
              {{ data.data.starttime }} to {{ data.data.endtime }}
            </span>
          </template>
          <DxColumn
            width="10%"
            :fixed="true"
            cell-template="apptimeCellTemplate"
            caption="Appointment Time"
          />
          <template #apptimeCellTemplate="{ data }">
            {{
              data.data.subtype ||
              data.data.appointmenttimeForDisplay === "Invalid date"
                ? ""
                : data.data.appointmenttimeForDisplay
            }}
            <i
              class="fa fa-star"
              style="color: yellow"
              v-if="data.data.issued"
              :title="issueTitle(data.data)"
            ></i>
          </template>

          <DxColumn
            width="15%"
            :fixed="true"
            cell-template="jobtypeCellTemplate"
            caption="Job Type"
          />
          <template #jobtypeCellTemplate="{ data }">
            <div v-if="!data.data.googleid">
              <!--span v-if="data.data.subtype">{{ data.data.subtype }}</!span-->
              {{ actProperty.getBookingTitle(data.data, dictionary) }}
            </div>
            <div v-else>Google</div>
          </template>

          <DxColumn
            width="25%"
            :fixed="true"
            cell-template="addressCellTemplate"
            caption="Address/Summary"
          />
          <template #addressCellTemplate="{ data }">
            <span style="white-space: pre-wrap">
              <span v-if="data.data.googleid">
                <div>{{ data.data.location }}</div>
                <div>{{ data.data.summary }}</div>
              </span>
              <span v-else-if="data.data.jobtype === 'Personal'">
                {{ data.data.summary }}
              </span>
              <span v-else>
                {{ data.data.addressPreview }}
              </span>
            </span>
          </template>

          <DxColumn
            width="10%"
            :fixed="true"
            data-field="inspector.name"
            caption="Inspector"
          />

          <DxColumn
            width="10%"
            :fixed="true"
            cell-template="companyNameCellTemplate"
            caption="Customer"
          />
          <template #companyNameCellTemplate="{ data }">
            <span v-if="data.data.subtype && data.data.leadbooking">
              {{ data.data.leadbooking.customer.companyName }}
            </span>
            <span v-else>
              {{ data.data.customer.companyName }}
            </span>
          </template>

          <DxColumn
            width="10%"
            :fixed="true"
            cell-template="branchNameCellTemplate"
            caption="Branch"
          />
          <template #branchNameCellTemplate="{ data }">
            <span v-if="data.data.subtype && data.data.leadbooking">
              {{ data.data.leadbooking.customer.branchName }}
            </span>
            <span v-else>
              {{ data.data.customer.branchName }}
            </span>
          </template>

          <DxPaging :enabled="false" />
        </DxDataGrid>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-sm-12">
        <div>
          <DxScheduler
            id="diary-scheduler"
            :key="`diary-scheduler-${bookings.length}`"
            ref="schedulerRef"
            time-zone="Etc/UTC"
            :data-source="bookings"
            :current-date="currentdate"
            :views="views"
            :groups="groups"
            :height="calendarheight"
            :width="width - width * 0.02"
            :show-all-day-panel="true"
            :all-day-panel-mode="'isAllDay'"
            :first-day-of-week="6"
            :start-day-hour="5"
            :end-day-hour="23.5"
            :cell-duration="15"
            :allow-adding="true"
            :allow-updating="false"
            :editing="editing"
            :appointment-dragging="appointmentdragging"
            :show-current-time-indicator="false"
            :indicator-time="new Date()"
            recurrence-exception-expr="recurrenceExclDates"
            recurrence-edit-mode="model"
            :on-content-ready="schedulerContentReady"
            current-view="day"
            text-expr="summary"
            start-date-expr="startdatefordiary"
            end-date-expr="enddatefordiary"
            appointment-template="bookingTemplate"
            resource-cell-template="inspectorTemplate"
            appointment-tooltip-template="bookingTooltipTemplate"
            time-cell-template="timeCellTemplate"
            max-appointments-per-cell="4"
            :cross-scrolling-enabled="true"
            :on-option-changed="onOptionChanged"
            :on-appointment-adding="onAppointmentAdding"
            :on-appointment-updated="onAppointmentUpdated"
            :on-appointment-updating="onAppointmentUpdating"
            :on-appointment-rendered="onAppointmentRendered"
            @appointment-click="onAppointmentClick"
            @appointment-dbl-click="onAppointmentDblClick"
            @appointment-form-opening="onAppointmentFormOpening"
          >
            <DxResource
              :data-source="employees"
              :allow-multiple="false"
              label="Inspectors"
              field-expr="employeeId"
            />

            <template #timeCellTemplate="{ data: cellData }">
              <TimeCellTemplate :cell-data="cellData" />
            </template>

            <template #inspectorTemplate="{ data: employee }">
              <InspectorTemplate
                :employee="employee"
                :highlightemployee="selectedemployeeid"
                :pidiary="false"
                @click="calculatetBookingTavelTimeForPI"
              />
            </template>

            <template #bookingTemplate="{ data }">
              <BookingTemplate
                :key="`diary-scheduler-${bookings.length}`"
                :template-model="data"
                :highlight="selectedbookingid"
                @show-sub-job-modal="showAddSubJobModal"
                @show-booking-cancel-modal="showCancelBookingModal"
                @locate-booking="locatebooking"
                :showtraveltime="showtraveltime"
              />
            </template>

            <template #bookingTooltipTemplate="{ data }">
              <BookingTooltipTemplate
                :template-model="data"
                @click="onAppointmentClick"
              />
            </template>

            <DxScrolling mode="standard" />
          </DxScheduler>
        </div>
        <AlertDialog ref="alertDialog" name="scalertdialog" />
        <AlertDialog ref="connectedBookingAlertDialog" name="cbalertdialog" />
        <BookingDetailModal
          id="schedular-booking-detail-model"
          ref="bookingDetailModal"
        />
        <RecursionConfirmModal
          id="delete-note-model"
          ref="recursionConfirmModal"
          :booking="doubleclickedbooking"
          @select="selectDeleteNoteModal"
          @selectedit="selectEditNoteModal"
        />
      </div>
    </div>

    <SubJobModal
      ref="editSubJobModal"
      id="add-subjob-modal"
      @updatedsubbooking="updateBookingInResults"
    />

    <SubJobModal ref="addSubJobModal" id="edit-subjob-modal" />

    <BookingCancelModal
      name="scheduler-cancel-booking-modal"
      ref="bookingCancelModal"
      @hide="hideBookingCancelModal"
    />

    <PersonalJobModal
      id="personal-job-modal"
      ref="personalJobModal"
      :booking="doubleclickedbooking"
    />

    <GoogleJobModal ref="googleJobModal" :booking="doubleclickedbooking" />

    <EmailModal
      :id="`deletebooking-email-modal`"
      :name="`deletebooking-email-modal`"
      ref="deletebookingEmailModal"
      title="Email to booking contact"
      :reporttype="selectedbooking.jobtype"
      target="Booked By"
      chronorder="Delete Booking"
      :fromaddress="fromaddress"
      :toaddresses="bookedbyaddresses"
    />
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onMounted,
  watch,
  inject,
  defineEmits,
  nextTick,
  onBeforeUnmount, 
  onUnmounted,
} from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";
import Ably from "ably";
import moment from "moment-timezone";
import { useEvent } from "@/eventBus";
import { debounce } from "lodash";
import _get from "lodash/get";
import _set from "lodash/set";

import { DxDateBox } from "devextreme-vue/date-box";
import { DxDataGrid, DxColumn, DxPaging } from "devextreme-vue/data-grid";
import { DxScheduler, DxSchedulerTypes, DxResource, DxScrolling } from "devextreme-vue/scheduler";

import BookingTemplate from "./BookingTemplate.vue";
import InspectorTemplate from "./InspectorTemplate.vue";
import BookingTooltipTemplate from "./BookingTooltipTemplate.vue";
import SearchByString from "@/components/diary/SearchByString.vue";
import AlertDialog from "@/components/modals/AlertDialog.vue";
import SubJobModal from "@/components/modals/SubJobModal.vue";
import BookingCancelModal from "@/components/modals/BookingCancelModal.vue";
import GoogleJobModal from "@/components/modals/GoogleJobModal.vue";
import PersonalJobModal from "@/components/modals/PersonalJobModal.vue";
import BookingDetailModal from "@/components/modals/BookingDetailModal.vue";
import EmailModal from "@/components/modals/EmailModal.vue";
import RecursionConfirmModal from "@/components/modals/RecursionConfirmModal.vue";
import TimeCellTemplate from "./TimeCellTemplate.vue";
import DataCellTemplate from "./DataCellTemplate.vue";
import UndoToaster from "@/components/modals/UndoToaster.vue";
import {
  Inspector,
  Booking,
  Location,
  TravelTime,
  DistanceMatrixResponse,
  SystemProperty,
  Bookedby,
  Report,
  Customer,
  DistanceMatrixRow,
  SelectOption,
  DistanceMatrixElement,
} from "@/models";
import { InspectorTravelTime } from "@/models/diary/inspectortraveltime.model";
const cancelBookingInStore = (booking: Booking): Promise<any> =>
  store.dispatch("diary/cancelBooking", booking);
const setBookingDeep = async (payload: { path: string; data: any }) => {
  await store.dispatch("diary/setBookingDeep", payload);
};
const store = useStore();
const route = useRoute();
const toasted = useToast();
const realtime: Ably.Realtime = inject("realtime");
const actProperty: any = inject("actProperty");
const channel = realtime.channels.get("diary");
const deviceid = actProperty.getDeviceId();

const width = ref(window.innerWidth);
const height = ref(window.innerHeight);
const editing = ref({
  allowAdding: true,
  allowDeleting: true,
  allowUpdating: true,
  allowResizing: true,
  allowDragging: true,
});
const appointmentdragging = ref({ autoScroll: true });
const selectedemployeeid = ref("");
const selectedbookingid = ref("");
const unsavedchangesonform = ref(false);
const diaryFormOpenConfirm = ref(false);
const recursionConfirmModal = ref(null);
const selectedinspectors = ref<Inspector[]>([]);
const loggedininspector = ref(new Inspector());
const showtraveltime = ref(false);
const results = ref<Booking[]>([]);
const focusedRowKey = ref("");
const showserachresulttable = ref(false);
const doubleclickedbooking = ref(new Booking());
const selectedbooking = ref(new Booking());
const schedulerHorizScrollPos = ref(0);

const bookingDetailModal = ref(null);
const alertDialog = ref(null);
const connectedBookingAlertDialog = ref(null);
const addSubJobModal = ref(null);
const editSubJobModal = ref(null);
const bookingCancelModal = ref(null);
const personalJobModal = ref(null);
const googleJobModal = ref(null);
const schedulerRef = ref(null);
const searchresultgrid = ref(null);
const deletebookingEmailModal = ref(null);

let newbookingid: string = '';
let formcurrentdate: string = '';
let recommendedtime: number = 0;
let startpostcodes: string[] = [];
let endpostcodes: string[] = [];
let toppilist: string[] = [];

const emit = defineEmits([
  "showpricelistdocument",
  "showworksorderdocument",
  "showpricelistdocument",
]);
const isBooking = computed(() => store.getters["auth/isBooking"]);
const isBookingManagement = computed(() => store.getters['auth/isBookingManagement']);
const email = computed(() => store.getters["auth/email"]);
const dictionary = computed(() => store.getters["dictionary/current"]);
const inspectorlist = computed(() => store.getters["diary/inspectorlist"]);
const bookings = computed(() => store.getters["diary/list"]);
const booking = computed(() => store.getters["diary/booking"]);
const _currentdate = computed(() => store.getters["diary/currentdate"]);
const systemproperties = computed(
  () => store.getters["dictionary/systemproperties"]
);
const showbookingswithproblems = computed(
  (): boolean => store.getters["diary/showbookingswithproblems"]
);

const setCurrentdate = (currentdate: Date) => {
  store.commit("diary/setCurrentdate", currentdate);
};

const insertBooking = (booking: Booking) => {
  store.commit("diary/insertBooking", booking);
};

const removeBooking = (id: string) => {
  store.commit("diary/removeBooking", id);
};

const setBooking = (booking: any) => {
  store.commit("diary/setBooking", booking);
};

const setShowbookingswithproblems = (flag: boolean) => {
  store.commit("diary/setShowbookingswithproblems", flag);
};

const getBookingsFromState = (payload?: { date?: string }) => {
  return store.dispatch("diary/getBookings", payload);
};

const getBookingsWithIdlist = (idlist: string[]): Promise<Booking[]> => {
  return store.dispatch("diary/getBookingsWithIdlist", idlist);
};

const addBooking = (booking: Booking) => {
  return store.dispatch("diary/addBooking", booking);
};

const saveBooking = (booking: Booking) => {
  return store.dispatch("diary/saveBookingFromScheduler", booking);
};

const updateBooking = (booking: Booking) => {
  return store.dispatch("diary/updateBooking", booking);
};

const getBookingWithoutStoringInState = (payload: {
  id: string;
  cancelled: string;
}) => {
  return store.dispatch("diary/getBookingWithoutStoringInState", payload);
};

const getBooking = (id: string) => {
  return store.dispatch("diary/getBooking", id);
};

const updateInspector = (inspector: Inspector) => {
  return store.dispatch("inspectors/updateInspector", inspector);
};

const getDistanceMatrix = (params: {
  from: string[];
  to: string[];
  mode: string;
}) => {
  return store.dispatch("diary/getDistanceMatrix", params);
};

const searchBookings = (params: any) => {
  return store.dispatch("diary/searchBookings", params);
};

const getPropertyBookings = async (bookingid: string) => {
  return await store.dispatch("property/getPropertyBookings", bookingid);
};

const getTraveltime = (payload: {
  booking: Booking;
  previousbooking: Booking | undefined;
  nextbooking: Booking | undefined;
}) => {
  return store.dispatch("diary/getTraveltime", payload);
};

const getTraveltimeTo = (payload: {
  obj1: Booking | Inspector;
  obj2: Booking | Inspector;
}) => {
  return store.dispatch("diary/getTraveltimeTo", payload);
};

const getTraveltimeToPostcodes = (payload: {
  origins: string[] | undefined,
  destinations: string[] | undefined,
  travelmode: string,
}): Promise<TravelTime> => {
  return store.dispatch("diary/getTraveltimeToPostcodes", payload);
};

const updateConnectedBooking = (id: string) => {
  return store.dispatch("diary/updateConnectedBooking", id);
};
const currentdate = computed({
  get: () => _currentdate.value,
  set: (val) => {
    // Assuming setCurrentdate is an adapted method for Vue 3
    setCurrentdate(val);
  },
});

const restoreBookingInStore = async (booking: any): Promise<Booking> => {
  return await store.dispatch("diary/restoreBooking", booking);
};

const markSMSEmailAsRead = async (id: string) => {
  return await store.dispatch("diary/markSMSEmailAsRead", id);
};

const groups = computed(() => {
  return ["employeeId"];
});

const views = computed(() => {
  return [];
});

const employees = computed(() => {
  return selectedinspectors.value
    .filter((i: Inspector) => !i.inactive && i.name.toLowerCase() != "default")
    .sort((insp1: Inspector, insp2: Inspector) => {
      let val: number = insp1.region.localeCompare(insp2.region);
      if (val === 0) val = insp1.name.localeCompare(insp2.name);
      return val;
    });
});

const filteredsortedinspectorlist = computed(() => {
  return inspectorlist.value
    .filter((i: Inspector) => !i.inactive && i.name.toLowerCase() != "default")
    .sort((insp1: Inspector, insp2: Inspector) => {
      let val: number = insp1.region.localeCompare(insp2.region);
      if (val === 0) val = insp1.name.localeCompare(insp2.name);
      return val;
    });
});

const inspectoroptions = computed((): Inspector[] => {
  return [...filteredsortedinspectorlist.value];
});

onMounted(() => {
  setShowbookingswithproblems(false);

  if (route.query.id) {
    const id: string = route.query.id.toString();
    getBookingWithoutStoringInState({ id, cancelled: "false" })
      .then((lb: Booking) => {
        if (lb.id) {
          setCurrentdate(lb.startdateAsDate);
          const paramdate = moment(lb.startdate, "DD-MM-YYYY")
            .utc(true)
            .toDate();
          getBookings({ date: formatDate(paramdate) }, () => {});
        }
      })
      .then(() => {
        if (route.query.from) {
          const from: string = route.query.from.toString();
          if (from === "unprocessedsms") {
            // Mark unread message flags as false
            markSMSEmailAsRead(id);
          }
        }
      });
  } else if (route.params.date) {
    const paramdate = moment(route.params.date, "DD-MM-YYYY")
      .utc(true)
      .toDate();

    setCurrentdate(paramdate);
    getBookings({ date: formatDate(paramdate) }, () => {});
  }

  channel.subscribe("newAppointmentAdded", (message: any) => {
    if (message?.data) {
      getBookingWithoutStoringInState({
        id: message.data.bookingid,
        cancelled: "false",
      }).then((b: Booking) => {
        if(b.id){
        const same = moment(currentdate.value).isSame(b.startdateAsDate, "day");
        if (same) {
          insertBooking(b);
        }
      }
      });
    }
  });

  channel.subscribe("newAppointmentsAdded", (message: any) => {
    if (message?.data) {
      getBookingWithoutStoringInState({
        id: message.data.bookingid,
        cancelled: "false",
      }).then((b: Booking) => {
        const same = moment(currentdate.value).isSame(b.startdateAsDate, "day");
        if (same) {
          const bookingidlist = message.data.bookingidlist;
          getBookingsWithIdlist(bookingidlist);
        }
      });
    }
  });

  channel.subscribe("appointmentDeleted", (message: any) => {
    if (message?.data && message.data.bookingid) {
      getBookingWithoutStoringInState({ id: message.data, cancelled: "" }).then(
        (b: Booking) => {
          if(b.id){
            removeBooking(b.id);
          }
        }
      );
    }
  });

  channel.subscribe("appointmentCancelled", (message: any) => {
    if (message?.data && message.data.bookingid) {
      getBookingWithoutStoringInState({
        id: message.data,
        cancelled: "true",
      }).then((b: Booking) => {
        appointmentCancelled(b);
      });
    }
  });

  channel.subscribe("appointmentRestored", (message: any) => {
    if (message?.data && message.data.bookingid) {
        getBookingWithoutStoringInState({
          id: message.data.bookingid,
          cancelled: "false",
        }).then((b: Booking) => {
          if(b.id){
          const same = moment(currentdate.value).isSame(b.startdateAsDate, "day");
          if (same || b.checkRecurrance(currentdate.value)) {
            insertBooking(b);
          }
        }
        });
    }
  });

  channel.subscribe("currentdateChanged", (message: any) => {
    if (message?.data) {
      // Make sure the event is coming from the same pc
      if (message.data.deviceid != deviceid) return;

      const selectedDate = actProperty.datetimeToUTC(message.data.bookingdate);
      const disabledDate = moment(message.data.bookingdate).format("YYYY-MM-DD");
      const same = moment(currentdate.value).isSame(selectedDate, "day");
      if (!same && disabledDate !== '2050-12-31') {
        setCurrentdate(selectedDate);
        setTimeout(() => {
          getBookings({ date: formatDate(selectedDate) }, () => {
            // if (message.data.newbooking && message.data.postcode) {
            //   calculatetBookingTavelTime();
            // }
          });
        }, 900);
      }
    }
  });

  channel.subscribe("appointmentSelectedInSearch", (message: any) => {
    if (message?.data && message.data.bookingid) {
      // Make sure the event is coming from the same pc
      const deviceid = actProperty.getDeviceId();
      if (message.data.deviceid != deviceid) return;

      if (message.data.bookingid) {
        getBookingWithoutStoringInState({
          id: message.data.bookingid,
          cancelled: "",
        }).then((booking: Booking) => {
          if (booking?.id && !booking.cancelled) {
            setCurrentdate(booking.startdateAsDate);
            getBookings(
              { date: formatDate(booking.startdateAsDate) },
              () => {}
            );
          }
        });
      } else {
        // Un-highlight booking box
        selectedbookingid.value = "";
        selectedemployeeid.value = "";
      }
    }
  });

  channel.subscribe("subJobCreated", (message: any) => {
    if (message?.data) {
      getBookingWithoutStoringInState({
        id: message.data,
        cancelled: "false",
      }).then((b: Booking) => {
        const same = moment(currentdate.value).isSame(b.startdateAsDate, "day");
        if (same) {
          insertBooking(b);
        }
      });
    }
  });

  channel.subscribe("appointmentChanged", async (message: any) => {
    const index = bookings.value.findIndex(
      (b: Booking) => b.id === message.data.bookingid
    );
    if (message?.data && message.data.bookingid) {
      if (message.data.deviceid === deviceid) {
        if (
          message.data.source != "Scheduler" ||
          message.data.target === "ConnectedBooking"
        ) {
          await getBookingWithoutStoringInState({
            id: message.data.bookingid,
            cancelled: "false",
          }).then(async (b: Booking) => {
            if (b.id) {
              if (index < 0) {
                await insertBooking(b);
              } else {
                await updateBooking(b);
              }
            }
          });
        }
      } else {
        await getBookingWithoutStoringInState({
          id: message.data.bookingid,
          cancelled: "false",
        }).then(async (b: Booking) => {
          if (b.id) {
            if (index >= 0) {
              await updateBooking(b);
            } else {
              const same = moment(currentdate.value).isSame(
                b.startdateAsDate,
                "day"
              );
              if (same) {
                insertBooking(b);
              }
            }
          }
        });
      }
    }
  });

  channel.subscribe("locateBooking", (message: any) => {
    if (message?.data) {
      // Make sure the event is coming from the same pc
      if (message.data.deviceid != deviceid) return;
      locatebooking({
        bookingid: message.data.bookingid,
        date: message.data.date,
      });
    }
  });

  channel.subscribe("unselectBooking", (message: any) => {
    if (message?.data) {
      if (message.data.deviceid != deviceid) return;
      selectedemployeeid.value = "";
      selectedbookingid.value = "";
    }
  });

  channel.subscribe("appointmentLoading", (message: any) => {
    if (message?.data) {
      if (message.data.deviceid != deviceid) return;
      if (!message.data.cancelled) {
        // If it is not a cancelled booking, highlight
        highlightbooking(
          message.data.bookingid,
          message.data.inspectorid,
          message.data.bookingdate
        );
      } else {
        // If it is a cancelled booking, don't highlight, just set the selectedbookingid
        // so that the 2 second timeout does not open another tab
        selectedbookingid.value = message.data.bookingid;
      }
    }
  });

  channel.subscribe(
    "unsavedChangesOnFormForExistingBooking",
    (message: any) => {
      if (message?.data) {
        if (message.data.deviceid != deviceid) return;
        selectedbookingid.value = message.data.bookingid;
        unsavedchangesonform.value = true;
        showUnsavedChangesAlert(
          "There are unsaved changes on the open booking form. Please first save all the changes to load the selected booking"
        );
      }
    }
  );

  channel.subscribe("unsavedChangesOnFormForNewBooking", (message: any) => {
    if (message?.data) {
      if (message.data.deviceid != deviceid) return;
      selectedbookingid.value = message.data.bookingid;
      showUnsavedChangesAlert(
        "There are unsaved changes on the open booking form. Please first save all the changes before starting a new booking"
      );
    }
  });

  channel.subscribe("appointmentSearchRowSelected", (message: any) => {
    if (showserachresulttable.value && message?.data) {
      if (message.data.deviceid != deviceid) return;
      if (message.data.bookingid) {
        focusedRowKey.value = message.data.bookingid;
      }
    }
  });

  channel.subscribe("diaryFormOpenConfirm", (message: any) => {
    if (message.data.deviceid != deviceid) return;
    diaryFormOpenConfirm.value = true;
  });

  channel.subscribe("postcodeSearch", async (message: any) => {
    if (message.data.deviceid != deviceid) return;
    if (!message.data.postcode) {
      // Clear the current travel figures if postcode is empty
      clearalltraveltime();
      return;
    }
    //if(!message.data.newbooking) return;
    // setTimeout(() => {
    //   calculatetraveltimetopostcode(message.data.postcode);
    // }, 500);
  });

  channel.subscribe("bookinglocked", (message: any) => {
    if (message?.data?.bookingid) {
      bookings.value.forEach((b: Booking, i: number) => {
        if (b.id === message.data.bookingid) {
          b.locked = true;
          b.lockedby = message.data.lockedby;
        } else if (b.lockedby === message.data.lockedby) {
          b.locked = false;
          b.lockedby = "";
        }
      });
    }
  });
  channel.subscribe("bookingunlocked", (message: any) => {
    if (message?.data?.bookingid) {
      bookings.value.forEach((b: Booking, i: number) => {
        if (b.id === message.data.bookingid) {
          b.locked = false;
          b.lockedby = "";
        } else if (b.lockedby === message.data.lockedby) {
          b.locked = false;
          b.lockedby = "";
        }
      });
    }
  });

  channel.subscribe(
    "appointmentSelectedOnManagementFilters",
    (message: any) => {
      if (message.data.deviceid != deviceid) return;
      // When a booking is selected on management filters
      // load the related booking for the same property on search table
      if (message?.data?.bookingid) {
        getPropertyBookings(message.data.bookingid).then(
          (bookings: Booking[]) => {
            processSearchResult(bookings, message.data.bookingid);
          }
        );
      }
    }
  );

  channel.subscribe('newbookingdetails', (message: any) => {
    if(message?.data) {
      if(message.data.deviceid != deviceid) return;
      newbookingid = message.data.newbookingid,
      formcurrentdate = message.data.formcurrentdate;
      recommendedtime = message.data.recommendedtime;
      startpostcodes = message.data.startpostcodes;
      endpostcodes = message.data.endpostcodes;
      toppilist = message.data.toppilist;

      // if(toppilist?.length) {
      //   calculatetBookingTavelTime();
      // }
    }
  });

  useEvent(
    "locateBooking",
    async (payload: { bookingid: string; date: string }) =>
      locatebooking(payload)
  );

  window.addEventListener("resize", () => {
    width.value = window.innerWidth;
    height.value = window.innerHeight;
  });

  selectedinspectors.value = [...inspectorlist.value];
  document.title = "Diary";
});

onBeforeUnmount(() => {
  channel.unsubscribe();
});

onUnmounted(() => {
  channel.unsubscribe();
});

const clearSelectedinspectors = () => {
  if (loggedininspector.value.id) {
    selectedinspectors.value = [];
    selectedinspectors.value.push(loggedininspector.value);
    loggedininspector.value.inspectors = [loggedininspector.value.id];
    updateInspector(loggedininspector.value);
  }
};

const selectallInspectors = () => {
  selectedinspectors.value = [...filteredsortedinspectorlist.value];
  if (loggedininspector.value.id) {
    loggedininspector.value.inspectors = selectedinspectors.value.map(
      (i: Inspector) => i.id
    );
    updateInspector(loggedininspector.value);
  }
};

const onInspectorSelect = (inspector: Inspector) => {
  if (loggedininspector.value.id) {
    let index = loggedininspector.value.inspectors.findIndex(
      (id: string) => inspector.id === id
    );
    if (index < 0) {
      loggedininspector.value.inspectors.push(inspector.id);
      updateInspector(loggedininspector.value);
    }
  }
};

const onInspectorRemove = (inspector: Inspector) => {
  if (loggedininspector.value.id) {
    if (inspector.id != loggedininspector.value.id) {
      let index = loggedininspector.value.inspectors.findIndex(
        (id: string) => inspector.id === id
      );
      if (index >= 0) {
        loggedininspector.value.inspectors.splice(index, 1);
        updateInspector(loggedininspector.value);
      }
    } else if (inspector.id === loggedininspector.value.id) {
      selectedinspectors.value.push(loggedininspector.value);
    }
  }
};

const isInspectorSelected = (inspectorid: string) => {
  let index = selectedinspectors.value.findIndex(
    (i: Inspector) => i.id === inspectorid
  );
  return index >= 0;
};

const calculatetraveltimetopostcode = async (postcode: string) => {
  // This is diabled - GOOGLE FIASCO
  clearalltraveltime();
  if (showinghistorical.value) return;

  // Calculate distancematrix from all booking postcodes to given postcode
  let filteredlist = bookings.value.filter(
    (b) => b.isMasterBooking() && b?.endpostcode?.trim().length > 0
  );
  let endpostcodes = filteredlist.map((b) => b?.endpostcode?.trim());
  await calculatetraveltimefrom(filteredlist, endpostcodes, postcode, "car");

  // Calculate distancematrix from given postcode to all booking postcodes
  let startpostcodes = filteredlist.map((b) => b?.startpostcode?.trim());
  await calculatetraveltimeto(filteredlist, postcode, startpostcodes, "car");

  // Calculate distancematrix from all inspectors address to given postcode
  let insplist = [...inspectorlist.value];
  let inspwithpclist = insplist.filter(
    (i) => i?.address?.postcode?.trim().length > 0
  );
  let inspectorpostcodes = inspwithpclist.map((i) =>
    i?.address?.postcode?.trim()
  );
  await calculatetraveltimefrom(
    inspwithpclist,
    inspectorpostcodes,
    postcode,
    "car"
  );
};

const showinghistorical = computed(() => {
  return moment
    .utc(currentdate.value)
    .isSameOrBefore(moment(new Date()).startOf("day"));
});

const totalinspectors = computed(() => {
  return inspectorlist.value.length;
});

watch(
  () => totalinspectors.value,
  () => {
    let loggedininspectorindex = inspectorlist.value.findIndex(
      (i: Inspector) => i.email === email.value
    );
    if (loggedininspectorindex >= 0) {
      loggedininspector.value = inspectorlist.value[loggedininspectorindex];
    }

    if (loggedininspector.value.id) {
      if (
        loggedininspector.value?.inspectors?.length > 0 &&
        inspectorlist.value?.length > 0
      ) {
        selectedinspectors.value = inspectorlist.value.filter(
          (i: Inspector) => {
            let index = loggedininspector.value.inspectors.findIndex(
              (id: string) => id === i.id
            );
            return index >= 0;
          }
        );
      } else {
        selectallInspectors();
      }
    } else {
      selectallInspectors();
    }
  }
);

// This is debouce to prevent calling getBookings from backend repeatedly
// Sometimes setting currentdate and siultaneously calling getBookings cause this
// const getBookings = debounce((params: any) => {
//   getBookingsFromState(params)
//     .then((bookings: Booking[]) => {
//       let calendarEl = schedulerRef.value as any;
//       calendarEl.instance._appointments.option("items", calendarEl.instance._getAppointmentsToRepaint());
//       locateQueryBooking();
//     });
// }, 500);

// This is debouce to prevent calling getBookings from backend repeatedly
// Sometimes setting currentdate and siultaneously calling getBookings cause this
const getBookings = debounce((params: any, callback: () => void) => {
  getBookingsFromState(params).then((bookings: Booking[]) => {
    let calendarEl = schedulerRef.value as any;
    calendarEl.instance._appointments.option(
      "items",
      calendarEl.instance._getAppointmentsToRepaint()
    );
    locateQueryBooking();
    if (callback) callback();
  });
}, 1000);

const locateQueryBooking = () => {
  if (route.query.id) {
    const id: string = route.query.id.toString();
    const lb: Booking | undefined = bookings.value.find(
      (b: Booking) => b.id === id
    );
    if (lb && lb.id) {
      highlightbooking(lb.id, lb.inspector?.id, "");
    }
  }
};

const locatebooking = async (payload: { bookingid: string; date: string }) => {
  const locateDate = moment(payload.date).utc(true).format("DD-MM-YYYY");
  const currDate = formatDate(currentdate.value);
  if (currDate != locateDate && payload.bookingid) {
    await getBooking(payload.bookingid).then(async (booking: Booking) => {
      setCurrentdate(booking.startdateAsDate);
      getBookings({ date: locateDate }, () => {});
    });
  } else {
    const booking: Booking | undefined = bookings.value.find(
      (b: Booking) => b.id === payload.bookingid
    );
  }
};

const highlightbooking = (
  bookingid: string,
  inspectorid: string,
  bookingdate: string
) => {
  if (bookingid || inspectorid) {
    selectedemployeeid.value = inspectorid;
    selectedbookingid.value = bookingid;
    let calendarEl = schedulerRef.value as any;
    if (bookingdate != "" && bookingdate != undefined) {
      currentdate.value = moment(bookingdate, "DD-MM-YYYY").utc(true).toDate();
    }
    calendarEl.instance.scrollTo(currentdate.value, {
      employeeId: inspectorid,
    });
  }
};

const prevdate = () => {
  currentdate.value = moment(currentdate.value)
    .utc()
    .subtract(1, "days")
    .toDate();
};

const nextdate = () => {
  currentdate.value = moment(currentdate.value).utc().add(1, "days").toDate();
};

const today = () => {
  currentdate.value = moment().utc().toDate();
};

const onAppointmentClick = (e: any) => {
  e.cancel = true;
  dbonAppointmentClick(e);
};

const dbonAppointmentClick = debounce((e: any) => {
  if (e.appointmentData || e.data) {
    let booking: Booking | undefined = undefined;
    if (e.appointmentData) booking = e.appointmentData;
    else if (e.data) booking = e.data;
    if (booking && booking?.subtype != Booking.PERSONAL) {
      const modal = bookingDetailModal.value as any;
      modal.init(booking);
      modal.show();
    }
  }
}, 250);

const onAppointmentDblClick = (e: any) => {
  e.cancel = true;
  dbonAppointmentClick.cancel();
  if (e.appointmentData || e.data) {
    let booking: Booking | undefined = undefined;
    let selectedfrom: string = "";
    if (e.appointmentData) {
      selectedfrom = "scheduler";
      booking = e.appointmentData;
    } else if (e.data) {
      selectedfrom = "searchtable";
      booking = e.data;
    }
    if (
      !booking.locked ||
      (booking.locked && booking.lockedby === email.value)
    ) {
      if (booking) {
        if (booking.googleid) {
          //selectBooking(booking, selectedfrom);
          doubleclickedbooking.value = booking;
          setTimeout(() => {
            showGoogleJobModal();
          }, 250);
        } else if (booking.jobtype === Booking.PERSONAL) {
          doubleclickedbooking.value = booking;
          //let calendarEl = schedulerRef.value as any;
          //calendarEl.instance.showAppointmentPopup(booking, false, booking);
          setTimeout(() => {
            editPersonalJob();
          }, 250);
        } else if (!booking.subtype) {
          selectBooking(booking, selectedfrom);
        } else {
          doubleclickedbooking.value = booking;
          if (booking.subtype === "Personal") {
            setTimeout(() => {
              editPersonalJob();
            }, 250);
          } else {
            setTimeout(() => {
              showEditSubJobModal();
            }, 250);
          }
        }
      }
    }
  }
};

const showAddSubJobModal = (book: Booking, subjobtype: string) => {
  const modal = addSubJobModal.value;
  if (modal) {
    modal.init(book, subjobtype);
    modal.add();
    modal.show();
  }
};

const showEditSubJobModal = () => {
  const modal = editSubJobModal.value;
  if (modal) {
    modal.init(doubleclickedbooking.value, doubleclickedbooking.value.subtype);
    modal.edit();
    modal.show();
  }
};

const addPersonalJob = () => {
  const modal = personalJobModal.value;
  if (modal) {
    doubleclickedbooking.value = new Booking();
    modal.init();
    modal.add();
    modal.show();
  }
};

const editPersonalJob = () => {
  const modal = personalJobModal.value;
  if (modal) {
    modal.init();
    modal.edit();
    modal.show();
  }
};

const newBooking = () => {
  diaryFormOpenConfirm.value = false;
  channel.publish("diaryFormOpenCheck", { deviceid: deviceid });
  setTimeout(() => {
    if (diaryFormOpenConfirm.value) {
      channel.publish("newBooking", { deviceid: deviceid });
    } else {
      window.open("/diary/new");
    }
  }, 1000);
};

const showGoogleJobModal = () => {
  const modal = googleJobModal.value;
  if (modal) {
    modal.init();
    modal.edit();
    modal.show();
  }
};

const selectBooking = (booking: Booking, selectedfrom: string) => {
  if (booking.googleid || !booking.jobtype) {
    locatebooking({ bookingid: booking.id, date: booking.startdate });
    selectedbooking.value = booking;
    highlightbooking(booking?.id, booking?.inspector?.id, "");
  } else if (booking.subtype) {
    locatebooking({ bookingid: booking.id, date: booking.startdate });
    selectedbooking.value = booking;
    highlightbooking(booking?.id, booking?.inspector?.id, "");
  } else {
    unsavedchangesonform.value = false;
    locatebooking({ bookingid: booking.id, date: booking.startdate });
    channel.publish("appointmentSelected", {
      deviceid: deviceid,
      bookingid: booking.id,
      inspectorid: booking.inspector?.id,
      googleid: booking.googleid,
      subtype: booking.subtype,
      jobtype: booking.jobtype,
    });
    selectedbooking.value = booking;

    // Wait for 2 seconds, to check if there is form open on another tab and the appointment loaded
    setTimeout(() => {
      if (selectedbooking.value.id != selectedbookingid.value) {
        // Open a new tab with this booking loaded
        window.open(`/diary/${selectedbooking.value.id}`, "_blank");
        highlightbooking(
          selectedbooking.value?.id,
          selectedbooking.value?.inspector?.id,
          ""
        );
      }

      if (selectedfrom === "searchtable") {
        // Only calculate traveltime for those selections which are coming from search result table
        // The traveltime for selections from scheduler are calculated separately
        // setTimeout(() => {
        //   calculatetraveltimetopostcode(selectedbooking.value.address.postcode);
        // }, 2000);
      }
      if (unsavedchangesonform.value) {
        selectedbookingid.value = "";
        unsavedchangesonform.value = false;
      }
    }, 2000);
  }
};

const onAppointmentFormOpening = (e: any) => {
  e.cancel = true;
  let booking: Booking = e.appointmentData;
  if (
    booking.googleid ||
    !booking.jobtype ||
    booking.jobtype === Booking.PERSONAL
  ) {
    doubleclickedbooking.value = new Booking();
    doubleclickedbooking.value.startDate = e.appointmentData.startdate;
    doubleclickedbooking.value.endDate = e.appointmentData.enddate;
    doubleclickedbooking.value.appointmenttime = e.appointmentData.startdate;
    doubleclickedbooking.value.inspector.id = e.appointmentData.employeeId;
    const modal = personalJobModal.value;
    if (modal) {
      modal.addwith(doubleclickedbooking.value);
      modal.show();
    }
    // const form = e.form;
    // let mainGroupItems = form.itemOption("mainGroup").items;
    // let formItems = form.option("items");
    // form.option(
    //   "items[0].items[1].items[0].editorOptions.displayFormat",
    //   "dd/MM/yyyy hh:mm a"
    // );
    // form.option(
    //   "items[0].items[1].items[2].editorOptions.displayFormat",
    //   "dd/MM/yyyy hh:mm a"
    // );
    // form.option("items[0].items[6].visible", false);
  }
};

const onAppointmentAdding = (e: any) => {
  let booking: Booking = new Booking(e.appointmentData);
  const startTime = moment(booking.startdate).format("THH:mm:ss.SSS[Z]");
  const endTime = moment(booking.enddate).format("THH:mm:ss.SSS[Z]");
  booking.startdate = subjobendDate(startTime, _currentdate.value);
  booking.enddate = subjobendDate(endTime, _currentdate.value);
  booking.jobtype = Booking.PERSONAL;
  booking.repeat = "";
  addBooking(booking);
  e.cancel = true;
  let calendarEl = schedulerRef.value as any;
  calendarEl.instance.hideAppointmentPopup(false);
};

const subjobendDate = (time: string, date: Date) => {
  let value = "";
  if (time) {
    let dt: Date = date ? date : _currentdate.value;
    let justdate = moment(dt).utc().format("YYYY-MM-DD");
    let justtime = moment(time, "hh:mm A").format("HH:mm");
    value = `${justdate}T${justtime}:00.000Z`;
  }
  return value;
};

const onAppointmentUpdating = async (e: DxSchedulerTypes.AppointmentUpdatingEvent) => {
  if (
    !e.newData.locked ||
    (e.newData.locked && e.newData.lockedby === email.value)
  ) {
    if (e) {
      if (e.oldData && e.newData) {
        // Only allow an appointment move within the bounds of working hours (7:30am to 9:00pm)
        const startdateMoment = moment.utc(e.newData.startdatefordiary);
        const startDate = startdateMoment.toDate(); 
        const endDate = moment.utc(e.newData.enddatefordiary).toDate(); 
        
        // Define boundaries in UTC
        const startBoundaryUTC = new Date(Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(), 7, 30, 0)); // 7:30 AM UTC
        const endBoundaryUTC = new Date(Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate(), 21, 0, 0)); // 9:00 PM UTC

        if (startDate.getTime() < startBoundaryUTC.getTime() || endDate.getTime() > endBoundaryUTC.getTime()) {
          e.cancel = true;
          return;
        }

        // Also only allow change of booking time if 
        // the appointment time is inside the new timing
        let changedbooking = new Booking(e.newData);
        changedbooking.startdate = e.newData.startdatefordiary;
        changedbooking.enddate = e.newData.enddatefordiary;
        if(changedbooking.appointmenttimeoutsidebooking) {
          // For any Inventory or Check-In booking where key release is via Tenant (confirmed or non-responded status), 
          // then you can only drag the booking vertically within the constrains of the appointment time. Hence if a 
          // booking is 9 till 11.30 with Check-In at 11, you could drag the appointment down 30 mins to start at 8.30, 
          // or delay it so it starts at 11am.
          // Crucially the appointment time always remains within the booking slot. If an appointment time is set to 
          // flexi or you are not meeting the tenant, then there is no restriction and you can drag to any time slot in that day.
          // You could shrink the appointment from 11.30 to finish at 11am (if key release is TT and unconfirmed or confirmed 
          // status) but not beyond, i.e. we cant leave at 10.30 if appointment at 11am as we’d miss the tenant.
          // You can shrink the appointment up from the start up to 11am with the same but converse logic as above.
          if((changedbooking.jobtype === 'inventory' || changedbooking.jobtype === 'checkin') 
            && changedbooking.releasekeysto === 'Meet Tenant'
            && changedbooking.tenantattending != 'no') {
            e.cancel = true;
            return;
          }
        }

        // For all job types, if access is via Landlord then the booking slot can only be moved horizontally, the start time 
        // is locked. If shrinking the booking you can shrink the end to make the appointment shorter but you can’t delay the 
        // start time.
        const appointmenttimeMoment = moment.utc(e.newData.appointmenttime); 
        if(changedbooking.keypickup === 'Via Landlord' && !startdateMoment.isSame(appointmenttimeMoment)) {
          e.cancel = true;
          return;
        }

        // For a Check-Out/SOC with access via Tenant, you can only move the booking horizontally, the start time has to 
        // remain the same as the appointment time. If access as not via the tenant or appointment time listed as flexi 
        // all day then there are no restrictions.
        // You can shrink the booking from the end time but not the start time, not relevant if TT/LL not attending.
        if((changedbooking.jobtype === 'checkout' || changedbooking.jobtype === 'soc') 
          && changedbooking.keypickup === 'Meet Tenant'
          && changedbooking.tenantattending != 'no' 
          && !startdateMoment.isSame(appointmenttimeMoment)) {
          e.cancel = true;
          return;
        }

        // If ‘Not tenant but fixed time’ selected as appointment time, then the booking can only be dragged horizontally. 
        // To edit the start and end times you’d have to physically open the booking form and edit them.
        const flexi = actProperty.getFlexi(changedbooking);
        if(flexi === 'No Tenant but Fixed Time' && !startdateMoment.isSame(appointmenttimeMoment)) {
          e.cancel = true;
          return;
        }

        // If there is a B2B CI, make sure that CI will be within the bounds of its appointment
        // if(changedbooking.connectedbooking?.id && changedbooking.connectedbooking?.internaljobtype === Booking.B2BCI) {
        //   let changedconnectedbooking = new Booking(changedbooking.connectedbooking);
        //   changedconnectedbooking.startdate = changedbooking.enddate;
        //   if (changedconnectedbooking.recommendedtime) {
        //     let time = changedconnectedbooking.preferredduration > 0
        //         ? changedconnectedbooking.preferredduration
        //         : changedconnectedbooking.recommendedtime;
        //       changedconnectedbooking.enddate = actProperty.prependDate(
        //       moment(changedbooking.enddate).utc().add(time, "minutes").format("hh:mm A"),
        //       changedbooking.enddate,
        //       changedbooking.enddate
        //     );
        //   }
        //   if(changedconnectedbooking.appointmenttimeoutsidebooking) {
        //     e.cancel = true;
        //     return;
        //   }
        // }
        
        if (
          e.oldData.inspector.id &&
          e.newData.employeeId &&
          e.oldData.inspector.id != e.newData.employeeId
        ) {
          // Here means the appointmetn was dragged from one pi to another
          if (e.oldData.subtype != Booking.KEY) {
            // In this case check if the new PI covers the area of this appointment
            const inspector: Inspector | undefined = inspectorlist.value.find(
              (i: Inspector) => i.id === e.newData.employeeId
            );
            let postcode: string = "";
            if (e.newData && e.newData.address) {
              postcode = e.newData.address.postcode;
            }
            if (inspector) {
              if(inspector.excludedclients?.length > 0) {
                const exclindex = inspector.excludedclients.findIndex((c: Customer) => c.id === changedbooking?.customer?.id);
                if(exclindex >= 0) {
                  alert(`${changedbooking.customer.companyName} - ${changedbooking.customer.branchName} is in excluded list for ${inspector.name}`)
                  e.cancel = true;
                  return;
                }
              }
              if(postcode) {
                postcode = postcode.replace(/ /g, "");
                let areacode = postcode.substring(0, postcode.length - 3).trim();
                let location: Location | undefined = inspector.locations.find(
                  (loc: Location) => loc.code.trim() === areacode
                );
                let message: string = "";
                if (!location) {
                  message = `${inspector.name} does not cover this area, do you still want to move the appointment?`;
                } else if (location.rating === 1) {
                  message = `${inspector.name} has rated this area as one star, do you still want to move the appointment?`;
                }
                if (message) {
                  e.cancel = new Promise((resolve, reject) => {
                    if (confirm(message)) {
                      resolve(false);
                    } else {
                      reject(true);
                    }
                  });
                }
              }

            }
          } else {
            // As this a key collection job, user is not allowed to change PI
            // So just cancel this update
            e.cancel = true;
          }
        }
      }
    }
  } else {
    e.cancel = true;
  }
};

const onAppointmentUpdated = async (e: any) => {
  if (e.appointmentData) {
    let updatedBooking = bookings.value.find(
      (b: any) => b.id === e.appointmentData.id
    );
    if (
      !booking.value.locked ||
      (booking.value.locked && booking.value.lockedby === email.value)
    ) {
      if (updatedBooking) {
        let duration = updatedBooking.duration;
        if (duration === updatedBooking.recommendedtime) duration = 0;
        // Make sure preferredduration is in 15 minutes interval
        duration = Math.ceil(duration / 15) * 15;
        updatedBooking.preferredduration = duration;

        /*let appointmenttime = "";
          let timeinminutes = 0;
          if (updatedBooking.preferredappointmenttime > 0)
            timeinminutes = updatedBooking.preferredappointmenttime;
          else if (updatedBooking.recommendedappointmenttime > 0)
            timeinminutes = updatedBooking.recommendedappointmenttime;
          if (updatedBooking.enddate && timeinminutes > 0) {
            appointmenttime = moment(updatedBooking.enddate)
              .utc()
              .subtract(timeinminutes, "minutes")
              .format("hh:mm A");
          } else if (updatedBooking.startdate) {
            appointmenttime = moment(updatedBooking.startdate)
              .utc()
              .format("hh:mm A");
          }
          updatedBooking.appointmenttime = this.prependDate(
            appointmenttime,
            updatedBooking.startdate
          );*/

        const appointmentDataBooking: Booking = new Booking(e.appointmentData);
        appointmentDataBooking.startdate = actProperty.roundDate(
          appointmentDataBooking.startdate
        );
        appointmentDataBooking.enddate = actProperty.roundDate(
          appointmentDataBooking.enddate
        );
        // if (appointmentDataBooking.subtype === Booking.PERSONAL && appointmentDataBooking.repeat !== "Doesn't repeat" && appointmentDataBooking.recurrenceRule) {
        //     const modal = recursionConfirmModal.value as any;
        //     modal.init(appointmentDataBooking);
        //     modal.editScheduler();
        //     modal.show();
        // } else {
        appointmentDataBooking.enddate = actProperty.prependDate(
          moment(appointmentDataBooking.enddate).utc().format("hh:mm A"),
          appointmentDataBooking.startdate,
          currentdate.value
        );
        let enddateMoment = moment(updatedBooking.enddate).utc();
        let selectetimeMoment = moment(updatedBooking.appointmenttime).utc();
        let inmiutesfromenddate = moment
          .duration(enddateMoment.diff(selectetimeMoment))
          .asMinutes();
        let recommendedappointmenttime =
          updatedBooking.recommendedappointmenttime;
        if (recommendedappointmenttime != inmiutesfromenddate)
          appointmentDataBooking.preferredappointmenttime = inmiutesfromenddate;
        else appointmentDataBooking.preferredappointmenttime = 0;

        // Check if inspector has changed
        const originalinspector =
          appointmentDataBooking.original("inspector.id");
        const newinspector = appointmentDataBooking.inspector.id;
        if (originalinspector != newinspector) {
          // Update traveltime in previous inspector's calendar
          const clonebooking = new Booking(appointmentDataBooking);
          clonebooking.inspector = new Inspector({ id: originalinspector });

          let filteredlist: Booking[] = bookings.value.filter(
            (b: Booking) =>
              b.isMasterBooking() && b.inspector.id === originalinspector
          );
          // This is just to update traveltime
          let traveltimebooking = actProperty.findPreviousBooking(
            clonebooking,
            filteredlist
          );
          if (!traveltimebooking)
            traveltimebooking = actProperty.findNextBooking(
              clonebooking,
              filteredlist
            );
          if (traveltimebooking) await saveBooking(traveltimebooking);
        }
        saveBooking(appointmentDataBooking).then((b: Booking) => {
          if (
            b?.connectedbooking?.id &&
            ((b?.jobtype === "checkin" &&
              b?.internaljobtype === "Check-In - back to back") ||
              b?.jobtype === "checkout")
          ) {
            updateConnectedBooking(b.id).then(() => {
              channel.publish("appointmentChanged", {
                deviceid: deviceid,
                bookingid: b.connectedbooking.id,
                source: "Scheduler",
                target: "ConnectedBooking",
              });
              if (b.connectedbooking.appointmenttimeoutsidebooking) {
                showConnectedBookingChangedAlert();
              }
            });
            channel.publish("appointmentChanged", {
              deviceid: deviceid,
              bookingid: b.id,
              source: "Scheduler",
              target: "ConnectedBooking",
            });
          } else {
            channel.publish("appointmentChanged", {
              deviceid: deviceid,
              bookingid: b.id,
              source: "Scheduler",
            });
          }

          if (b.appointmenttimeoutsidebooking) showBookingChangedAlert();

          // Also if there is a key collection job, change PI of that job
          b.subbookings
            .filter((sb: Booking) => sb.subtype === Booking.KEY)
            .forEach((kb: Booking) => {
              kb.inspector = new Inspector(b.inspector);
              saveBooking(kb).then((keycollection: Booking) => {
                channel.publish("appointmentChanged", {
                  deviceid: deviceid,
                  bookingid: keycollection.id,
                  source: "Scheduler",
                });
                showKeyCollectionMovedAlert();
              });
            });
        });
      }
    }
  }
};

const onAppointmentRendered = (e: any) => {
  if (e.appointmentData?.isAllDay) {
    e.appointmentElement.style.height = `30px`;
    const cssText = e.appointmentElement.style.cssText;
    const startIndex = cssText.indexOf("translate(");
    if (startIndex >= 0) {
      const endIndex = cssText.indexOf(")", startIndex);
      if (endIndex >= startIndex) {
        const translate = cssText.substring(startIndex + 10, endIndex);
        if (translate) {
          const splits = translate.split(",");
          if (splits?.length) {
            const translateY = splits[1].trim();
            if (translateY === "11.5px") {
              e.appointmentElement.style.marginTop = "20px";
            }
          }
        }
      }
    }
  }
};

const selectionStarted = ref(false);
const schedulerContentReady = (e: any) => {
  e.component.getWorkSpaceScrollable().option("showScrollbar", "always");
  e.component.getWorkSpaceScrollable().option("useNative", "true");
  e.component._workSpace._dateTableScrollable.option("scrollByContent", false);

  let calendarEl = schedulerRef.value as any;
  e.element.onmouseup = (upevent: any) => {
    if (selectionStarted.value) {
      const selectedCellData = calendarEl.instance.option("selectedCellData");
      if (selectedCellData.length > 0) {
        doubleclickedbooking.value = new Booking();
        doubleclickedbooking.value.startDate =
          moment(selectedCellData[0].startDate)
            .utc()
            .format("YYYY-MM-DD[T]HH:mm") + ":00.000Z";
        doubleclickedbooking.value.endDate =
          moment(selectedCellData[selectedCellData.length - 1].endDate)
            .utc()
            .format("YYYY-MM-DD[T]HH:mm") + ":00.000Z";
        doubleclickedbooking.value.inspector.id =
          selectedCellData[0].groups.employeeId;
        const modal = personalJobModal.value;
        if (modal) {
          modal.addwith(doubleclickedbooking.value);
          modal.show();
        }
      }
    }
    selectionStarted.value = false;
  };
  e.element.onmousedown = () => {};
  e.element.onmousemove = () => {};

  const schedulerScrollable = calendarEl.instance._workSpace.getScrollable();
  schedulerScrollable.on("scroll", function (args) {
    schedulerHorizScrollPos.value = schedulerScrollable.scrollOffset();
  });
  // Restore the horizontal scroll position
  schedulerScrollable.scrollTo(schedulerHorizScrollPos.value);
};

const onOptionChanged = (args: any) => {
  if (args.name === "currentDate") {
    getBookings({ date: formatDate(args.value) }, () => {});
  } else if (args.name === "selectedCellData") {
    selectionStarted.value = true;
  }
};

const showBookingChangedAlert = () => {
  const modal = alertDialog.value as any;
  if (modal) {
    modal.init(
      "Warning",
      "Booking time has changed. Resend notifications",
      "Yes, I Understand"
    );
    modal.show();
  }
};

const showConnectedBookingChangedAlert = () => {
  const modal = connectedBookingAlertDialog.value as any;
  if (modal) {
    modal.init(
      "Warning",
      "Connected Booking time has changed. Resend notifications",
      "Yes, I Understand"
    );
    modal.show();
  }
};

const showKeyCollectionMovedAlert = () => {
  const modal = alertDialog.value as any;
  if (modal) {
    modal.init(
      "Info",
      "Just to inform you, key collection has been moved with master job",
      "OK"
    );
    modal.show();
  }
};

const showUnsavedChangesAlert = (message: string) => {
  const modal = alertDialog.value as any;
  if (modal) {
    modal.init("Warning", message, "Ok");
    modal.show();
  }
};

const appointmentCancelled = (cancelledbooking: Booking) => {
  // if it is a Prep job, resize the leadbooking
  const leadbooking = cancelledbooking.leadbooking;
  if (leadbooking && leadbooking.id) {
    if (cancelledbooking.subtype === Booking.PREP) {
      getBookingWithoutStoringInState({
        id: leadbooking.id,
        cancelled: "false",
      }).then(async (lb: Booking) => {
        lb.jobtype = cancelledbooking.jobtype;
        lb.internaljobtype = cancelledbooking.internaljobtype;
        lb.startdate = actProperty.prependDate(
          moment(lb.enddate)
            .utc()
            .subtract(cancelledbooking.recommendedtime, "minutes")
            .format("hh:mm A"),
          lb.startdate
        );
        await saveBooking(lb).then((b: Booking) => {
          channel.publish("appointmentChanged", {
            deviceid: deviceid,
            bookingid: lb.id,
            source: "Scheduler",
          });
        });
      });
    }

    // Also remove the cancelled booking from subbokings if present in any of the existing bookings
    const index = bookings.value.findIndex(
      (b: Booking) => b.id === leadbooking.id
    );
    if (index >= 0) {
      let lb = bookings.value[index];
      if (lb?.subbookings?.length) {
        const sbindex = lb.subbookings.findIndex(
          (sb: Booking) => sb.id === cancelledbooking.id
        );
        if (sbindex >= 0) {
          lb.subbookings.splice(sbindex, 1);
          lb.subbookings = [...lb.subbookings];
          updateBooking(lb);
        }
      }
    }
  }
  removeBooking(cancelledbooking.id);
};

const formatDate = (date: Date) => {
  return moment(date).format("DD-MM-YYYY");
};

const bookingwatch = computed(() => {
  let value = `${booking.value.jobtype},${booking.value.customer.companyName},${booking.value.customer.branchName},${booking.value.starttime},${booking.value.endtime}`;
  if (booking.value.address) {
    value = `${value},${booking.value.address.displayaddress}`;
  }
  return value;
});

watch(
  () => bookingwatch.value,
  () => {
    let calendarEl = schedulerRef.value as any;
    let appointment = calendarEl.dataSource.find(
      (a: any) => a.id === booking.value.id
    );
    if (appointment) {
      calendarEl.instance.updateAppointment(appointment, {
        ...booking.value,
      });
    }
  }
);

const search = (val: string) => {
  searchBookings({ anytext: val, cancelled: "all", sort: "created_at", limit: 100 }).then(
    (bookings: Booking[]) => {
      processSearchResult(bookings, booking.value.id);
    }
  );
};

const selectEditNoteModal = async (val: any, book: Booking) => {
  let bookingcopy = bookings.value.find((b: any) => b.id == book.id);
  if (val === "thisevent") {
    bookingcopy.addExclDate(_currentdate.value);
    saveBooking(bookingcopy)
      .then((savednpb: Booking) => {
        // Remove current booking from the list
        removeBooking(savednpb.id);
        // Now save the new changes as a single entry personal booking
        book.id = "";
        book.startdate = actProperty.prependDate(
          moment(book.startdate).utc().format("hh:mm A"),
          _currentdate.value,
          _currentdate.value
        );
        book.enddate = actProperty.prependDate(
          moment(book.enddate).utc().format("hh:mm A"),
          _currentdate.value,
          _currentdate.value
        );
        book.repeat = `Doesn't repeat`;
        book.recurrenceRule = "";
        book.recurrenceExclDates = "";
        addBooking(book).then((newbooking) => {
          insertBooking(newbooking);
          hiderecusionmodel();
          toasted.success("Booking saved");
        });
      })
      .catch((err: any) => {
        actProperty.displayError(err);
      });
  } else if (val === "thisandfollowingevent") {
    const datebeforecurrentdate = moment(_currentdate.value)
      .utc()
      .subtract(1, "day")
      .endOf("day")
      .toDate();
    bookingcopy.setRecurrenceEnddate(datebeforecurrentdate);
    saveBooking(bookingcopy).then((savednpb: Booking) => {
      // Reaload current booking
      removeBooking(savednpb.id);
      // Now save the new changes as a single entry personal booking
      book.id = "";
      book.startdate = actProperty.prependDate(
        moment(book.startdate).utc().format("hh:mm A"),
        _currentdate.value,
        _currentdate.value
      );
      book.enddate = actProperty.prependDate(
        moment(book.enddate).utc().format("hh:mm A"),
        _currentdate.value,
        _currentdate.value
      );
      addBooking(book).then((newbooking) => {
        insertBooking(newbooking);
        hiderecusionmodel();
        toasted.success("Booking saved");
      });
    });
  } else {
    await saveBooking(book)
      .then(() => {
        channel.publish("appointmentChanged", {
          deviceid: deviceid,
          bookingid: book.id,
          source: "Scheduler",
        });
        hiderecusionmodel();
      })
      .catch((err: any) => {
        hiderecusionmodel();
        actProperty.displayError(err);
      });
  }
};

const hiderecusionmodel = () => {
  const modal = recursionConfirmModal.value as any;
  modal.hide();
};

const processSearchResult = (bookings: Booking[], bookingid: string) => {
  results.value = [];
  bookings.sort((b1: Booking, b2: Booking) => {
    if (moment.utc(b1.startdate).isAfter(moment.utc(b2.startdate))) {
      return 1;
    } else if (moment.utc(b1.startdate).isBefore(moment.utc(b2.startdate))) {
      return -1;
    } else {
      return 0;
    }
  });
  let todaymoment = moment.utc(new Date()).startOf("day");
  var futurebooking: Booking | undefined = bookings.find((b: Booking) =>
    moment.utc(b.startdate).isAfter(todaymoment)
  );
  if (!futurebooking || !futurebooking?.id) {
    // If there is not future booking, select the last one in the list
    futurebooking =
      bookings.length > 0 ? bookings[bookings.length - 1] : undefined;
  }
  results.value.push(...bookings);
  showserachresulttable.value = true;
  nextTick(() => {
    if (futurebooking?.id) {
      focusedRowKey.value = bookingid ? bookingid : futurebooking?.id;
      const grid: any = searchresultgrid.value as any;
      grid.instance.navigateToRow(futurebooking?.id);
      setTimeout(() => {
        const scrollOffset = grid.instance.getScrollable().scrollOffset();
        grid.instance.getScrollable().scrollTo(scrollOffset.top);
      }, 1000);
    }
  });
};

const calendarheight = computed(() => {
  if (showserachresulttable.value) {
    return height.value - height.value * 0.15 - 220;
  } else {
    return height.value - height.value * 0.15;
  }
});

const onSearchTableRowPrepared = (e: any) => {
  if (e.rowType === "data") {
    if (e.data.cancelled) {
      e.rowElement.classList.add("cancelled-booking");
    }
  }
};

const onSearchTableCellPrepared = (e: any) => {
  if (e.rowType === "data") {
    if (e.data.cancelled) {
      e.cellElement.classList.add("transparent-background");
    }
  }
};

watch(
  () => width.value,
  () => {
    let calendarEl = schedulerRef.value as any;
    calendarEl.instance.repaint();
  }
);

const toggleShowbookingswithproblems = () => {
  setShowbookingswithproblems(!showbookingswithproblems.value);
};

const clearalltraveltime = () => {
  bookings.value.forEach((b: Booking) => {
    b.clearTravelTimes();
    b.traveltime = new TravelTime();
  });
  inspectorlist.value.forEach((i: Inspector) => i.clearTravelTimes());
};

const calculatetraveltimefrom = async (
  list: any[],
  from: string[],
  to: string,
  mode: string
) => {
  let fieldPrefix: string = "from";
  let listsubarrays: any[][] = chunkArray(list);
  let fromsubarrays: any[][] = chunkArray(from);
  for (let i = 0; i < listsubarrays.length; i++) {
    await callgoogleapi(
      listsubarrays[i],
      fromsubarrays[i],
      [to],
      fieldPrefix,
      mode
    );
  }
  var sortedlist: any[] = [...list];
  sortedlist = sortedlist.sort((a1: any, a2: any) => {
    const value1: number = _get(a1, `${fieldPrefix}DurationSecs`);
    const value2: number = _get(a2, `${fieldPrefix}DurationSecs`);
    return value1 - value2;
  });
  for (var i = 0; i < 5; i++) {
    if (sortedlist.length >= i) {
      const value = _get(sortedlist[i], `${fieldPrefix}DurationSecs`);
      if (value < 5941) {
        const properPrefix =
          fieldPrefix.charAt(0).toUpperCase() +
          fieldPrefix.substring(1).toLowerCase();
        _set(sortedlist[i], `top${properPrefix}`, true);
      }
    }
  }
};

const showtraveltimeFn = async () => {
  showtraveltime.value = !showtraveltime.value;
};
const calculatetraveltime = async () => {

  showtraveltime.value = true;
  clearalltraveltime();
  
  for (let i = 0; i < employees.value.length; i++) {
    let insp: Inspector = employees.value[i];
    await calculatetManagementTavelTimeForPI(insp);
  }
};

const calculatetManagementTavelTimeForPI = async (insp: Inspector) => {
  if(!isBookingManagement.value) return;
  showtraveltime.value = true;
  insp.calculatingtraveltime = true;
  let filteredlist: Booking[] = bookings.value.filter(
    (b: Booking) => {
      let flag = true;
      if(b.inspector.id != insp.id) flag = false;
      else if(!b.isMasterBooking() && !b.isPrepOrSharedOrKeyOrRevisit()) flag = false;
      return flag;
    }
  );

  let unsortedlist = [...filteredlist];
  let sortedlist = unsortedlist.sort((b1, b2) => {
    if (moment.utc(b1.startdate).isAfter(moment.utc(b2.startdate))) {
      return 1;
    } else if (
      moment.utc(b1.startdate).isBefore(moment.utc(b2.startdate))
    ) {
      return -1;
    } else {
      return 0;
    }
  });
  for (let j = 0; j < sortedlist.length; j++) {
    let booking: Booking = sortedlist[j];

    if( j === 0) {
      // If this is first job in the list
      // Calculate travel time from PI home to this job
      booking.traveltime = new TravelTime(); // Clear previous values
      if (
        insp?.address?.postcode &&
        insp?.transport &&
        booking?.address?.postcode
      ) {
        await getTraveltimeTo({obj1: insp, obj2: booking}).then(
          (traveltime: TravelTime) => {
            booking.traveltime = traveltime;
          }
        );
      }
    }
    else {
      const previousbooking = findprevious(sortedlist, booking);
      const nextbooking = undefined;    // findnext(sortedlist, booking);
      booking.traveltime = new TravelTime(); // Clear previous values
      if (previousbooking || nextbooking) {
        await getTraveltime({ booking, previousbooking, nextbooking }).then(
          (traveltime: TravelTime) => {
            booking.traveltime = traveltime;
          }
        );
      }
    }
  }
  insp.calculatingtraveltime = false;
};

async function clearTravelTime() {
  inspectorlist.value.forEach((insp: Inspector) => {
    insp.calculatingtraveltime = false;
    insp.fromDurationSecs = -1;
    insp.fromDurationText = '';
  });

  bookings.value.forEach((book: Booking) => {
    book.fromDurationSecs = -1;
    book.fromDurationText = '';
    book.toDurationSecs = -1;
    book.toDurationText = '';
  });

}

async function calculatetBookingTavelTime() {
  clearTravelTime();
  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  const formDate = moment(formcurrentdate).format("YYYY-MM-DD");
  if(formDate != todayDate && formDate != '2050-12-31' && formDate != 'Invalid date') {   // 2050-12-31 Comes out as Invalid date!
    for(let i = 0; i < toppilist.length; i++) {
      let index = inspectorlist.value.findIndex((insp: Inspector) => insp.id === toppilist[i]);
      if(index >= 0) {
        calculatetBookingTavelTimeForPI(inspectorlist.value[index]);
      }
    }
  }
}

const calculatingtraveltime = computed(() => {
  const insp: Inspector = inspectorlist.value?.find((i: Inspector) => i.calculatingtraveltime === true);
  return insp?.id;
});

async function calculatetBookingTavelTimeForPI(pi: Inspector) {
  showtraveltime.value = true;
  pi.calculatingtraveltime = true;
  let checkDate = moment(currentdate.value).utc().format("YYYY-MM-DD");
  if (formcurrentdate === '31-12-2050' || formcurrentdate === 'Invalid date'|| checkDate != formcurrentdate) return;
  if (newbookingid) return;
  
  let pibookings: Booking[] = bookings.value.filter(
    (b: Booking) => {
      let flag = true;
      if(b.inspector.id != pi.id) flag = false;
      else if(!b.isMasterBooking() && !b.isPrepOrSharedOrKeyOrRevisit()) flag = false;
      return flag;
    }
  );

  let currentdatevalue = moment(currentdate.value).utc().format('YYYY-MM-DD');
  const filteredBookings = pibookings.filter(b =>  {
    if(!b.jobtype) return false;
    if(b.allDay) return false;
    let bookingstartdate = moment(b.startdate).utc().format('YYYY-MM-DD');

    if(b.recurrenceRule) {
      // Check if this recurring event is falling on to today
      return b.checkRecurrance(currentdate.value)
    }
    else {
      return bookingstartdate === currentdatevalue;
    }
  });
  filteredBookings.sort((a, b) => moment.utc(a.starttime, "h:mm a").diff(moment.utc(b.starttime, "h:mm a")));

  let pitt = await getTraveltimeToPostcodes({origins: [pi?.address?.postcode], destinations: startpostcodes, travelmode: pi?.travelmode});
  pi.fromDurationSecs = pitt.from;
  pi.fromDurationText = pitt.fromText;

  for(let i = 0; i < filteredBookings.length; i++) {
    let pibooking = filteredBookings[i];

    // if both start and end postcodes are missing, don't calculate
    if(!pibooking.startpostcode && !pibooking.endpostcode) continue;

    let lefttt = await getTraveltimeToPostcodes({origins: endpostcodes, destinations: pibooking.startpostcodes, travelmode: pi?.travelmode});
    if(lefttt.from) {
      pibooking.toDurationSecs = lefttt.from;
      pibooking.toDurationText = lefttt.fromText;
    }

    let righttt = await getTraveltimeToPostcodes({origins: pibooking.endpostcodes, destinations: startpostcodes, travelmode: pi?.travelmode});
    if(righttt.from) {
      pibooking.fromDurationSecs = righttt.from;
      pibooking.fromDurationText = righttt.fromText;
    }
  }
  pi.calculatingtraveltime = false;
}

const findprevious = (
  list: Booking[],
  booking: Booking
): Booking | undefined => {
  let previous: Booking | undefined = undefined;
  for (let i = 0; i < list.length; i++) {
    let b: Booking = list[i];
    if (b.id === booking.id) {
      if (i > 0) {
        previous = list[i - 1];
        break;
      }
    }
  }

  // This will exlude any B2B booking
  if(previous?.connectedbooking?.id === booking?.id) return undefined

  return previous;
};

const findnext = (list: Booking[], booking: Booking): Booking | undefined => {
  let next: Booking | undefined = undefined;
  for (let i = 0; i < list.length; i++) {
    let b: Booking = list[i];
    if (b.id === booking.id) {
      if (i + 1 < list.length) {
        next = list[i + 1];
        break;
      }
    }
  }

  // This will exlude any B2B booking
  if(next?.connectedbooking?.id === booking?.id) next = undefined;

  return next;
};

const calculatetraveltimeto = async (
  list: any[],
  from: string,
  to: string[],
  mode: string
) => {
  let fieldPrefix: string = "to";
  let listsubarrays: any[][] = chunkArray(list);
  let tosubarrays: any[][] = chunkArray(to);
  for (let i = 0; i < listsubarrays.length; i++) {
    await callgoogleapi(
      listsubarrays[i],
      [from],
      tosubarrays[i],
      fieldPrefix,
      mode
    );
  }
  var sortedlist: any[] = [...list];
  sortedlist = sortedlist.sort((a1: any, a2: any) => {
    const value1: number = _get(a1, `${fieldPrefix}DurationSecs`);
    const value2: number = _get(a2, `${fieldPrefix}DurationSecs`);
    return value1 - value2;
  });
  for (var i = 0; i < 5; i++) {
    if (sortedlist.length >= i) {
      const value = _get(sortedlist[i], `${fieldPrefix}DurationSecs`);
      if (value < 5941) {
        const properPrefix =
          fieldPrefix.charAt(0).toUpperCase() +
          fieldPrefix.substring(1).toLowerCase();
        _set(sortedlist[i], `top${properPrefix}`, true);
      }
    }
  }
};

const chunkArray = <T>(array: T[], chunkSize: number = 25): T[][] => {
  const chunks: T[][] = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }

  return chunks;
};

const callgoogleapi = async (
  list: any[],
  from: string[],
  to: string[],
  fieldPrefix: string,
  mode: string
) => {
  list.forEach((a: any) => {
    _set(a, `${fieldPrefix}DurationText`, "");
    _set(a, `${fieldPrefix}DurationSecs`, -1);
  });

  await getDistanceMatrix({ from, to, mode }).then(
    (response: DistanceMatrixResponse) => {
      if (response?.status === "OK" && response?.rows?.length) {
        if (from.length === 1 && response.rows.length) {
          for (var i = 0; i < response.rows[0].elements.length; i++) {
            if (
              response.rows[0]?.elements?.length &&
              response.rows[0]?.elements[i]?.status === "OK" &&
              response.rows[0]?.elements[i]?.duration
            ) {
              _set(
                list[i],
                `${fieldPrefix}DurationText`,
                response.rows[0].elements[i].duration.text
              );
              _set(
                list[i],
                `${fieldPrefix}DurationSecs`,
                response.rows[0].elements[i].duration.value
              );
            }
          }
        } else {
          for (var j = 0; j < response.rows.length; j++) {
            if (
              response.rows[j]?.elements?.length &&
              response.rows[j]?.elements[0]?.status === "OK" &&
              response.rows[j]?.elements[0]?.duration
            ) {
              _set(
                list[j],
                `${fieldPrefix}DurationText`,
                response.rows[j].elements[0].duration.text
              );
              _set(
                list[j],
                `${fieldPrefix}DurationSecs`,
                response.rows[j].elements[0].duration.value
              );
            }
          }
        }
      }
    }
  );
};

const hideBookingCancelModal = (val: boolean, book: Booking) => {
  if (
    val &&
    book.jobtype != Booking.PERSONAL &&
    book.subtype != Booking.PERSONAL &&
    !book.googleid
  ) {
    setBooking(book);
    nextTick(() => {
      showDeleteBookingEmailDialog();
    });
  }
};

const showDeleteBookingEmailDialog = () => {
  const modal = deletebookingEmailModal.value as any;
  modal.init();
  modal.show();
};

const bookedbyaddresses = computed(() => {
  let toaddresses: string[] = [];
  if (booking.value && booking.value.bookedby && booking.value.bookedby.length)
    toaddresses = booking.value.bookedby
      .filter((b: Bookedby) => b.bbemail)
      .map((b: Bookedby) => b.bbemail);
  return [...toaddresses];
});

const fromaddress = computed(() => {
  let prop = systemproperties.value?.find(
    (sp: SystemProperty) => sp.propertykey === "booking.email.fromaddress"
  );
  return prop ? prop?.value : "";
});

const showCancelBookingModal = (book: Booking) => {
  if (book.subtype === Booking.PERSONAL) {
    if (book.repeat !== "Doesn't repeat" && book.recurrenceRule) {
      const modal = recursionConfirmModal.value as any;
      modal.init(book);
      modal.deletes();
      modal.show();
    } else {
      directDeleteBooking(book);
    }
  } else {
    const modal = bookingCancelModal.value;
    modal.init(book);
    modal.show();
  }
};

const directDeleteBooking = (book: Booking) => {
  cancelBookingInStore(book)
    .then(() => {
      channel.publish("appointmentCancelled", book.id);
      toasted.success(UndoToaster, {
        // Define the onClick event handler
        onClick: () => {
          restoreBookingInStore(book)
            .then((b: Booking) => {
              book.cancelled = false;
              toasted.success("Booking restored");
              // channel.publish('appointmentRestored', { deviceid: deviceid, bookingid: book.id });
              setBookingDeep({ path: "auditlog", data: b.auditlogs });
            })
            .catch((err: any) => actProperty.displayError(err));
        },
        timeout: 10000,
        closeOnClick: true,
      });
    })
    .then(() => {
      // Clear connectedbookingflag
      setBookingDeep({ path: "connectedbooking", data: "" });
    })
    .catch((err: any) => {
      actProperty.displayError(err);
    });
};

const previousRecurrencerule = ref("");
const previousRecurrenceruleexcldate = ref("");
const selectDeleteNoteModal = (val: any, book: Booking) => {
  previousRecurrencerule.value = book.recurrenceRule;
  previousRecurrenceruleexcldate.value = book.recurrenceExclDates;
  if (val === "thisevent") {
    book.addExclDate(currentdate.value);
    saveBooking(book)
      .then((savednpb: Booking) => {
        // Remove this booking from the list
        removeBooking(book.id);
        channel.publish("appointmentChanged", {
          deviceid: deviceid,
          bookingid: book.id,
          source: "Scheduler",
        });
        //toasted.success("Booking deleted");
        toasted.success(UndoToaster, {
          // Define the onClick event handler
          onClick: () => {
            book.recurrenceRule = previousRecurrencerule.value;
            book.recurrenceExclDates = previousRecurrenceruleexcldate.value;
            saveBooking(book)
              .then((b: Booking) => {
                toasted.success("Booking restored");
                channel.publish("appointmentRestored", {
                  deviceid: deviceid,
                  bookingid: book.id,
                });
              })
              .catch((err: any) => actProperty.displayError(err));
          },
          timeout: 10000,
          closeOnClick: true,
        });
      })
      .catch((err: any) => {
        actProperty.displayError(err);
      });
  } else if (val === "thisandfollowingevent") {
    // End this booking by setting recurrence until day before current date
    const datebeforecurrentdate = moment(currentdate.value)
      .utc()
      .subtract(1, "day")
      .endOf("day")
      .toDate();
    book.setRecurrenceEnddate(datebeforecurrentdate);
    saveBooking(book)
      .then((savednpb: Booking) => {
        // Remove this booking from the list
        removeBooking(book.id);
        channel.publish("appointmentChanged", {
          deviceid: deviceid,
          bookingid: book.id,
          source: "Scheduler",
        });
        // toasted.success("Booking deleted");
        toasted.success(UndoToaster, {
          // Define the onClick event handler
          onClick: () => {
            book.recurrenceRule = previousRecurrencerule.value;
            book.recurrenceExclDates = previousRecurrenceruleexcldate.value;
            saveBooking(book)
              .then((b: Booking) => {
                toasted.success("Booking restored");
                channel.publish("appointmentRestored", {
                  deviceid: deviceid,
                  bookingid: book.id,
                });
              })
              .catch((err: any) => actProperty.displayError(err));
          },
          // Additional options like timeout, close button, etc.
          timeout: 10000,
          closeOnClick: true, // Set to false if you don't want the toast to close on click
        });
      })
      .catch((err: any) => {
        actProperty.displayError(err);
      });
  } else if (val === "allevents") {
    directDeleteBooking(book);
  }

  const modal = recursionConfirmModal.value as any;
  modal.hide();
};

const issueTitle = (b: Booking) => {
  let findIssueData = actProperty.findLastIssueData(b.auditlogs, "issued");
  if (b.issued && (findIssueData === null || b.auditlogs.length === 0))
    return "Job issued to client";
  let date = moment(findIssueData.datetime).format("DD-MM-YYYY");
  let username = findIssueData.user;
  return `Issued - ${date} - ${username}`;
};

const updateBookingInResults = (updatedBooking: Booking) => {
  const index = results.value.findIndex((b) => b.id === updatedBooking.id);
  if (index !== -1) {
    results.value[index] = updatedBooking;
    processSearchResult(results.value, updatedBooking.id);
  }
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.cancelled-booking {
  background: repeating-linear-gradient(
    135deg,
    rgb(136, 8, 8, 0.2),
    rgb(136, 8, 8, 0.2) 5px,
    rgb(255, 255, 255) 5px,
    rgb(255, 255, 255) 10px
  );
  color: #212529;
}

.cancelled-booking:hover {
  background: repeating-linear-gradient(
    135deg,
    rgb(217, 218, 219),
    rgb(217, 218, 219) 5px,
    rgb(255, 255, 255) 5px,
    rgb(255, 255, 255) 10px
  );
  color: #212529;
}
</style>

<style lang="scss">
.dx-scheduler-group-header,
.dx-scheduler-date-table-cell {
  position: relative;
}

.dx-scheduler-group-header-content > .selected {
  background-color: rgb(197, 197, 197);
}

.datebox .dx-texteditor-input {
  //width: 180px !important;
}

.today-button {
  min-width: 73px;
}

.dx-scheduler-date-table-other-month.dx-scheduler-date-table-cell {
  opacity: 1;
  color: rgba(0, 0, 0, 0.3);
}

.dx-scheduler-date-table-cell .dx-template-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-right: 6px;
}

.dx-scheduler-group-header,
.dx-scheduler-cell-sizes-horizontal {
  width: 150px;
}

.dx-scheduler-cell-sizes-vertical {
  height: 1.1669vh !important;
}

.dx-scheduler-time-panel-cell {
  border: none;
}

.dx-scheduler-date-table-row:nth-child(4n + 1) {
  border-top: 3px solid #25374699;
}

.dx-scheduler-date-table-row:nth-child(4n + 2) {
  border-top: hidden !important;
}

.dx-scheduler-date-table-row:nth-child(4n + 4) {
  border-top: hidden !important;
}

.dx-template-wrapper {
  overflow: hidden !important;
}

.dx-scrollable-container {
  overflow-y: auto !important;
}

.datebox ::v-deep .dx-datebox-wrapper-calendar > .dx-calendar {
  min-width: 282px;
}

.dx-overlay-content {
  max-width: 100vw !important;
}

.dx-scheduler-header {
  display: none !important;
}

.dx-scheduler-header-panel-container {
  border-top: 1px solid #ebebeb;
}

.datebox {
  border: none !important;
}

tr {
  cursor: pointer;
}

.search-table-container {
  max-height: 220px;
  overflow-y: scroll;
}

.selectedbooking {
  //background-color: #c7e0f5;
  font-weight: 700;
}

.selectall-button {
  height: 40px;
}

.dx-scheduler *::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dx-scheduler-work-space-flex-container ::-webkit-scrollbar:vertical,
.dx-scheduler-work-space-flex-container ::-webkit-scrollbar-thumb:vertical {
  display: none !important;
}

.dx-datagrid {
  border: 1px solid #253746 !important;
  border-radius: 0.25rem;
}

.dx-datagrid-headers {
  padding-right: 0 !important;
}

.dx-header-row {
  color: #eadb40;
  background-color: #253746 !important;
}

.dx-row-focused.cancelled-booking,
.dx-row-focused.cancelled-booking:hover {
  color: #ffffff !important;
  background: repeating-linear-gradient(
    135deg,
    #5c96c5,
    #5c96c5 5px,
    #052c4c 5px,
    #052c4c 10px
  );
  background-color: transparent !important;
  font-weight: bolder;
}

.dx-row-focused,
.dx-row-focused:hover {
  font-weight: bolder;
}

.dx-row-focused > .transparent-background,
.dx-row-focused > .transparent-background:hover {
  //color: #212529 !important;
}

.dx-scheduler-group-header {
  text-align: center !important;
}

.transparent-background {
  background: transparent !important;
}
</style>

<style>
.dx-scheduler-all-day-table,
.dx-scheduler-all-day-title,
.dx-scheduler-all-day-table-row {
  height: 70px !important;
  line-height: 70px !important;
}

.dx-scheduler-all-day-appointmentx {
  height: 2.4669vh !important;
}

.dx-scheduler-appointment-reduced-icon {
  display: none !important;
}
</style>
